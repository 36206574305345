import React from "react";
import { graphql } from "gatsby";
import { Grid, Modal, Button } from "semantic-ui-react";
import Helmet from "react-helmet";
import Markdown from "markdown-to-jsx";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  About,
  ImageCollection,
  Contact,
  SocialMedia,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";
import { getPageHeroImages, getPageGalleryImages } from "../utils/images";

export default class extends React.PureComponent {
  state = {
    open: false,
  };
  render() {
    const data = this.props.data.allFisherman.edges[0].node;
    const pageData = this.props.data.fishermanWebsitePage;
    const pageComponents = (pageData && pageData.components) || [];
    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Home</title>
          <meta name="description" content={pageData.description} />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={data.businessName}
              tagline={"Culinary Herbs"}
              images={getPageHeroImages(pageComponents)}
              showMap={false}
              ctas={[
                {
                  text: "DOORDASH",
                  link: "https://www.doordash.com/store/ophelia's-blue-vine-kansas-city-24270426/?pickup=false",
                  internal: false,
                  className: "",
                },
                {
                  text: "Desperately Seeking",
                  link: "/desperately-seeking/",
                  internal: true,
                },
                {
                  link: "https://fisherman.gumlet.io/public/opheliasbluevine/Ophilias_Recipe.pdf",
                  text: "Recipes",
                  internal: false,
                },
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          {/* <Grid
            stackable
            className="component-section-container rm-padding-top"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <About
                content={<Markdown>{data.aboutMarkdown}</Markdown>}
                header={``}
                centerContent={true}
                headerAs={null}
                withContainer={true}
              />
              <form
                action="https://www.paypal.com/donate"
                method="post"
                target="_top"
              >
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="W9FK7ZBUXMDEL"
                />
                <input
                  type="image"
                  src="
https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </Grid.Column>
          </Grid> */}
          <Grid
            stackable
            className="social-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <SocialMedia
                socialMediaValues={data.socialMedia}
                buttonType={"circle"}
                centerHeader={true}
                colors={"secondary"}
                groupButtons={false}
                groupVertically={false}
                header={""}
                inverted={false}
                labelPosition={"free"}
                showLabels={false}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12}>
              <h1>Featured Video</h1>
              <div>
                <p style={{marginBottom:0}}>Ophelia's Blue Vine Farm has been selected to participate in the first cohort of NMSDC’s and Cargill’s Black Farmers Equity Initiative.</p>
                <a href="https://fisherman.gumlet.io/public/opheliasbluevine/NMSDC_Announces_First_Cohort_of_Black_Farmers_Equity_Initiative_-_National_Minority_Supplier_Development_Council.pdf" target={"_blank"}>
                  LEARN MORE
                </a>
              </div>
              <br />
              <iframe
                  width="650"
                  height="450"
                  src={"https://www.youtube.com/embed/KYXZcoYjibw?rel=0"}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
            </Grid.Column>
          </Grid>
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <ImageCollection
                as={"carousel"}
                centered={true}
                cutUnevenRows={true}
                disable={"none"}
                header={"Gallery"}
                images={getPageGalleryImages(pageComponents)}
                numberOfRowDisplayItems={2}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable className="component-section-container">
            <Grid.Column width={16}>
              <Contact
                phoneNumber={data.phone}
                email={data.email}
                address={{
                  street: data.address.street,
                  city: data.address.city,
                  state: data.address.state,
                  zip: data.address.zip,
                }}
                hours={data.hours}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
          phone
          email
          socialMedia {
            link
            type
          }
          address {
            street
            city
            state
            zip
          }
          hours {
            close
            day
            label
            location
            open
            id
          }
        }
      }
    }
    fishermanWebsitePage(pageType: { eq: "Home" }) {
      description
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
